<template>
  <div>
    <Tabs v-model="warehouseTypeData" :animated="false" class="tabs_styles">
      <TabPane v-for="item in warehouseType" :label="item.label" :name="item.value"></TabPane>
    </Tabs>
    <component :is="warehouseTypeData === '1' ? 'inventoryLevels' :'ymsInventoryLevels'"></component>
  </div>
</template>
<script>
import { alias47916751af154eb5b47cd5de9d34633d } from '@/customFolder/customVueAlias.js';

import Mixin from '@/components/mixin/common_mixin';
import ymsInventoryLevels from '@/views/supplier/components/wms/wms-inStock/ymsInventoryLevels';
import inventoryLevels from "@/views/supplier/components/wms/wms-inStock/inventoryLevels.vue";
import {setConfigCharacters} from "@/utils/common";

export default {
  name: 'inventoryTab',
  mixins: [Mixin],
  data() {
    return {
      warehouseTypeData: '1',
      warehouseType: [
        {label: alias47916751af154eb5b47cd5de9d34633d.t('key1005969'), value: '1'},
        {label: `${setConfigCharacters}${alias47916751af154eb5b47cd5de9d34633d.t('key1005850')}`, value: '2'}
      ]
    };
  },
  components: {
    ymsInventoryLevels,
    inventoryLevels
  }
};
</script>

<style lang="less" scoped>
.tabs_styles {
  /deep/ .ivu-tabs-bar {
    border-bottom: none;
    background-color: #fff;
    padding: 8px 0 3px 0;
    margin: 0;
  }
}
</style>
