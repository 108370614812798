<template>
  <Modal v-model="importModal" :title="importTitle" :transfer="false" :mask-closable="false" :width="modalWidth"
    @on-visible-change="importModalChange">
    <Form ref="importSalesPriceForm" :label-width="80" @submit.native.prevent>
      <Row align="middle" justify="space-between">
        <Col span="24" class="no_margin_style mb10" v-if="importType === 'YmsStock'">
          <Form-item :label="`${configCharacters}` + $t('key1000246')">
            <Select filterable style="width: 200px" v-model="ymsWarehouseId">
              <Option v-for="item in warehouseList" :key="item.ymsWarehouseId" :value="item.ymsWarehouseId">{{ item.name }}</Option>
            </Select>
          </Form-item>
        </Col>
        <div class="flex align-items-center mb10" style="width: 100%;">
          <Col span="15" class="no_margin_style">
            <Form-item :label="$t('key1000247')" prop="file">
              <Upload
                style="display: inline-block"
                :headers="headObj"
                ref="uploadRef"
                :name='fileName'
                :data="uploadData"
                :show-upload-list="false"
                :action="uploadAction"
                :before-upload="beforeHandleUpload"
                :on-success="uploadSuccess"
                :on-error="uploadError"
                :on-format-error="handleFormatError"
                :max-size="uploadFilesMaxSize"
                :format="importFormat">
                <Button class="mr10">
                  <i class="iconfont">&#xe663;</i>
                  {{ $t('key1000250') }}
                </Button>
              </Upload>
            </Form-item>
          </Col>
          <a @click="download">{{ $t('key1000251') }}</a>
        </div>
        <Col span="20" class="no_margin_style mb10" v-if="excleFile">
          <Form-item :label="$t('key1000248')">
            <span>{{ excleFile.name }}</span>
          </Form-item>
        </Col>
        <Col span="23" push="1" class="no_margin_style" v-if="importTypeList.includes(importType)">
          <Form-item :label="$t('key1000249')" :label-width="120" class="radio_styles">
            <RadioGroup v-model="existence" v-if="['Location', 'LogisticsBill'].includes(importType)">
              <Radio :label="0">{{ $t('key1000252') }}</Radio>
              <Radio :label="1">{{ $t('key1000253') }}</Radio>
            </RadioGroup>
            <RadioGroup v-model="isCover" v-if="importType === 'YmsStock'">
              <Radio :label="1">{{ $t('key1000254') }}</Radio>
              <Radio :label="0">{{ $t('key1000255') }}</Radio>
            </RadioGroup>
          </Form-item>
        </Col>
        <Col span="18" push="4">
          <div class="tips_styles">
            <slot></slot>
          </div>
        </Col>
      </Row>
    </Form>
    <template #footer>
      <Button @click="importModal = false">{{ $t('key1000098') }}</Button>
      <Button type="primary" @click="saveImport">{{ importOKText }}</Button>
    </template>
  </Modal>
</template>

<script>
import { alias47916751af154eb5b47cd5de9d34633d } from '@/customFolder/customVueAlias.js';

import Mixin from "@/components/mixin/common_mixin";
import {downloadTemplate, setConfigCharacters} from "@/utils/common";

export default {
  name: "commonImportModal",
  mixins: [Mixin],
  props: {
    // 导入的标题
    importTitle: {
      type: String,
      default: alias47916751af154eb5b47cd5de9d34633d.t('key1000258')
    },
    // 导入的接口
    uploadAction: {
      type: String,
      default: null
    },
    // 导入的文件格式
    importFormat: {
      type: Array,
      default: () => {
        return ['xls', 'xlsx', 'csv'];
      }
    },
    // 导入按钮文本
    importOKText: {
      type: String,
      default: alias47916751af154eb5b47cd5de9d34633d.t('key1000097')
    },
    // 下载导入模板的文件
    downloadText: {
      type: String,
      default: ''
    },
    // 导入的默认参数 是 excleFile
    fileName: {
      type: String,
      default: 'excleFile'
    },
    // 上传需要的参数
    parameter: {
      type: Object,
      default: () => {
        return {}
      }
    },
    modalWidth: {
      type: Number,
      default: 600
    },
    // 导入数据的处理方式
    /**
     * 运营系统->库位管理-->导入库位：Location
     * 运营系统->库存余量-->导入库存：YmsStock
     * 供应商系统->（库存余量--> 自有仓库导入Excel、商品管理---> 导入库存）：privateWarehouse
     * 运营系统-->财务管理-->物流账单--> 导入物流账单：LogisticsBill
     */
    importType: {
      type: String,
      default: ''
    },
    // 仓库下拉的基础数据
    warehouseList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      importTypeList: ['Location', 'YmsStock', 'LogisticsBill'],
      importModal: false,
      excleFile: null, // 导入的文件
      existence: 0, // 忽略、更新
      ymsWarehouseId: null, // 云卖仓库
      isCover: 1, // 库存余量-->导入库存，导入的数据已存在时默认覆盖数据
      uploadData: {}, // 上传时附带的额外参数
      formatError: false,
    }
  },
  methods: {
    // 上传文件之前
    beforeHandleUpload(excleFile) {
      let obj = {};
      let v = this;
      v.excleFile = excleFile;
      // 供应商系统->库存余量--> 自有仓库导入Excel
      if (v.importType === 'privateWarehouse') {
        obj.warehouseId = v.getWarehouseId()
      }
      // 运营系统->库存余量-->导入库存
      if (v.importType === 'YmsStock') {
        obj.isCover = v.isCover;
        obj.ymsWarehouseId = v.ymsWarehouseId;
      }
      /**
       * 运营系统-->库位管理-->库位导入
       * 运营系统-->财务管理-->物流账单--> 导入物流账单
       */
      if (['Location', 'LogisticsBill'].includes(v.importType)) {
        obj.importType = v.existence;
      }
      v.uploadData = Object.assign({}, obj);
      return false;
    },
    // 上传文件失败
    uploadError() {
      this.formatError = true;
      this.importModal = true;
      this.$Message.error(alias47916751af154eb5b47cd5de9d34633d.t('key1000259'));
      return false;
    },
    // 上传文件格式异常
    handleFormatError() {
      this.formatError = true;
      this.importModal = true;
      this.$Message.error(alias47916751af154eb5b47cd5de9d34633d.t('key1000115'));
      return false;
    },
    // 上传文件成功
    uploadSuccess(res, file, fileList) {
      let v = this;
      let code = res.code;
      if (code === 0) {
        v.importModal = false;
        let data = res.datas || [];
        if (data.length > 0) {
          v.$Modal.warning({
            title: alias47916751af154eb5b47cd5de9d34633d.t('key1000260'),
            content: `${alias47916751af154eb5b47cd5de9d34633d.t('key1000256')}${data.join('、')}`,
            okText: alias47916751af154eb5b47cd5de9d34633d.t('key1000205')
          });
        } else {
          v.$Message.success(alias47916751af154eb5b47cd5de9d34633d.t('key1000261'));
          setTimeout(() => {
            v.$emit('updateData', true);
          }, 2000);
        }
      }
      let codeList = [
        {code: 1112781, text: alias47916751af154eb5b47cd5de9d34633d.t('key1000262'), errorMessage: true},
        {code: 112816, text: alias47916751af154eb5b47cd5de9d34633d.t('key1000263')},
        {code: 112817, text: alias47916751af154eb5b47cd5de9d34633d.t('key1000264')},
        {code: 112820, text: alias47916751af154eb5b47cd5de9d34633d.t('key1000265')},
        {code: 112838, text: alias47916751af154eb5b47cd5de9d34633d.t('key1000266')},
        {code: 112839, text: alias47916751af154eb5b47cd5de9d34633d.t('key1000267')},
      ];
      for (let i = 0; i < codeList.length; i++) {
        let item = codeList[i];
        if (item.code === code) {
          if (item.errorMessage) {
            let errorMessage = res.others.errorMessage;
            v.$Message.error(item.text + errorMessage);
          } else {
            v.$Message.error(item.text);
          }
          v.importModal = false;
          return false;
        }
      }
    },
    // 模板下载
    download() {
      if (this.downloadText) {
        downloadTemplate(this.downloadText);
      } else {
        this.$Message.warning(alias47916751af154eb5b47cd5de9d34633d.t('key1000268'));
        return false;
      }
    },
    // 导入的确定按钮
    saveImport() {
      let v = this;
      v.importModal = true;
      if (v.importType === 'YmsStock') {
        if (!v.ymsWarehouseId) {
          this.$Message.error(`${alias47916751af154eb5b47cd5de9d34633d.t('key1000087')}${setConfigCharacters}${alias47916751af154eb5b47cd5de9d34633d.t('key1000257')}`);
          return false;
        }
      }
      if (v.excleFile) {
        v.$refs.uploadRef.post(this.excleFile); // 手动上传
        if (!v.formatError) {
          setTimeout(() => {
            v.excleFile = null;
            v.importModal = false;
          }, 2000);
        }
      } else {
        v.$Message.warning(alias47916751af154eb5b47cd5de9d34633d.t('key1000269'));
        return false;
      }
    },
    // 关闭弹窗重置数据
    reset() {
      this.excleFile = null;
      this.existence = 0;
      this.ymsWarehouseId = null;
      this.isCover = 1;
      this.uploadData = {};
    },
    // 监听导入的弹窗
    importModalChange(value) {
      if (value) {
        if (this.importType === 'YmsStock') {
          if (this.warehouseList.length > 0) {
            this.ymsWarehouseId = this.warehouseList[0].ymsWarehouseId;
          }
        }
      } else {
        this.reset();
      }
    }
  }
}
</script>

<style lang="less" scoped>
.no_margin_style {
  /deep/ .ivu-form-item {
    margin: 0 !important;
  }
}

.tips_styles {
  color: #9a9797;
  margin: 12px 0 20px 0;
}
</style>
